export const bgText = "This is the first design project I’ve worked on that I didn’t come up with the idea for. Instead, I worked with the founder of a new soon-to-be startup, Zyno, to design his solution to a problem his clients, realtors, had been having.";
export const problemText = "They had found maintaining social media to take up too much time, and because they weren’t able to devote enough time to it, they also weren’t getting nearly enough return on their investment in terms of impressions and conversions. And the few that were getting enough return on investment had to pay someone to do it full time. All of them needed a way to automate turning a listing into a social media post.";
export const solutionText = "That’s where Zyno came in. The proposed solution was to crawl the realtor’s listing pages and use AI to automatically create a social media post for them to review, edit, and post in under 15 minutes per post. Zyno’s founder was going to just build something quick and ugly himself, but when he and I spoke about it, I realized we could both get a lot out of working together and letting me design a solution for him.";
export const affinityMapText1 = "The logical first step was for me to ";
export const affinityMapBold1 = "interview";
export const affinityMapText2 = " some of the realtors he had spoken to already to gain a better insight about their needs, and what tools they were using that were currently failing them. I used an "
export const affinityMapBold2 = "affinity map";
export const affinityMapText3 = " to help me find patterns in the interview responses.";
export const researchText1 = "With a little bit more robust understanding of the space, I was better equipped to understand current and future "
export const researchBold1 = "competition";
export const researchText2 = " likely to come from the largest names in the space - Compass, Realtor, Redfin, and Zillow. Thankfully, all of them were missing automated social media advertising.";
export const personaText = "I finally had enough information to come up with a persona, focusing on a realtor’s busy life, and desire to focus on customer interaction.";
export const projectGoalText = "With the research stage done, it was time to get down to brass tacks with Zyno’s founder and come up with the Project Goals.";
export const problemStatmentText = "I used those to create problem statements.";
export const featureSetText1 = "Those problem statements informed the creation of a ";
export const featureSetBold1 = "Feature Set";
export const featureSetText2 = ".";
export const userFlowText1 = "And that feature set allowed me to create a "
export const userFlowBold1 = "User Flow";
export const userFlowText2 = ".";
export const lofiText1 = "After that, I was finally ready for Branding. The Zyno founder had told me he wanted a more professional and modern look. I asked him if that meant he wanted a more serious look, and he said “absolutely not”. I started with three alternative color schemes - only black and white for professionalism, a contrasting theme using orange for the action and a bold brutalist-inspired blue, and a tealish blue based color scheme. The Zyno founder liked the black and white color scheme focusing on more at first, so I tried out a quick Low-Fidelity mockup to help me figure out what he would like.";
export const logoText = "He absolutely hated it, and was unable to articulate why. He later sent me a few links that had the nextjs aesthetic, which all had a very blue-focused color scheme. In order to waste as little time as possible, I started working on logo options in parallel, using the more teal blue color as the base. He really liked having all of these options, and ended up choosing the Z looking logo because it also looked like a roof.";
export const lofiText2 = "At this point, I was ready to try out a couple color schemes and alternate designs via "
export const lofiBold2 = "user testing";
export const lofiText3 = " to tune my ideas. I created two low-fidelity wireframes to help narrow down which kinds of features and color schemes the actual target customers responded best to, and found a mix of both, with neither color scheme or feature set really being perfect.";
export const buttonText1 = "Around this time, I realized that ";
export const buttonBold1 = "my client was only happy when I gave them lots of varied options";
export const buttonText2 = ". I took what the users liked, and started playing with color schemes and buttons to get a good gauge of what could look readable, and how I could modify those buttons to showcase various button states. I ran that by the Zyno founder, and he loved the number of options available to him.";
export const typefaceText = "He picked what he liked, which let me move on to typefaces. In order to give a similar impression to the sites he liked, I floated very neutral sans-serif fonts that had been growing in popularity. He picked Nunito Sans for the body text. I then played with bolder sans-serif fonts for titles and headers, and let him choose between Roboto and Montserrat. He chose Montserrat.";
export const hifiText1 = "Using the user feedback, and the Zyno founder’s, I came up with a high-fidelity wireframe.";
export const hifiText2 = "I showed it to him, and he asked for changes that did not follow good UX, and so I gave him his version, and moved ahead with usability testing with a different set of realtors, and turned their feedback into a High-Fidelity Prototype.";
export const conclusionText = "This project helped me learn how to deal with difficult clients and stakeholders who want a large amount of, and frequent instances of, exerting influence on a project. I found that working ahead on aspects of a project slightly out of the order I would normally do things in was necessary to get things done on time, and keep to schedule.\n\nIn future projects, I will likely give stakeholders fewer occasions to give feedback, and prepare more options when I do share in order for them to feel secure and in control. I also learned that it's important to integrate client inspirations and expectations early on to ensure high efficiency.\n\nIf I were to work further on this project, I'd likely want to design for the various filters in the Edit Post screen or design for alternate vectors beyond just Real Estate.";