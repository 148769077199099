export const bgText1 = "I started learning ";
export const bgText2 = "crochet";
export const bgText3 = " during the pandemic, and as I improved, I found myself needing to modify patterns in order to achieve the desired result. However, when I looked for apps or websites that had solutions, all of the solutions were inefficient, and worse than just printing out a copy of the pattern and writing over it. I found that other fiber artists had the same problem that I did, and wanted a similar solution.";
export const researchText1 = "Before I even conceptualized this as a project, I had failed to find apps or websites that could perform the function that I was looking for. However, Ravelry, Pocket Crochet, My Row Counter, and Ravit all had aspects of things I wanted in my app and were the most successful apps out there. I completed a small SWOT "
export const researchBold1 = "competitive analysis"
export const researchText1P2 = " on them and learned that not having an in-app stitch counter and an intuitive standardized way to read patterns were the most common gripes, so those would have to be my main 2 features in the app.";
export const researchText2 = "I now needed to speak with individuals in the space to gain better clarity over and confirm what would be important to include in the app. I ";
export const researchBold2 = "interviewed";
export const researchText2P2 = " Crochet and Knitting artists, and picked their brains on what other features they would want from a Crochet app. 80% felt that the app needed to include a stitch counter. All of them wanted to be able to follow along with a pattern and remember where they left off, no matter how long ago they had last worked on it. One person gave me a quote that really helped conceptualize it. They called it";
export const researchText3 = "That led me to looking up how popular book apps like kindle and aldiko handle bookmarks. All of them implemented a seamless, invisible bookmark. So, to follow that pattern, it made sense to implement the same.\n\nAnother thing that came up during user interviews was that the more experienced fiber artists all wanted a way to know which lines had been modified from the original version of the uploaded pattern. To address this, I took inspiration from word processors and text editors. I found using color changes and a * character before the title or line were both used to denote modifications. Since color also denotes other kinds of information within the row, I decided to go with the * option at the beginning of the line.\n\nAll of this information I collated in this ";
export const researchBold3 = "affinity map";
export const researchText3P2 = ".";
export const personaText1 = "I found the following common statistics.";
export const personaText2 = "Which I then turned into a ";
export const personaBold2 = "Persona";
export const personaText2P2 = ", to whom I could refer when I was making design decisions, as she was a representative of my target customers.";
export const producDefText1 = "After completing my initial research, I concretely defined the project goals.";
export const producDefText2 = "I used these goals to create problem statements.\n\n\"How might we help";
export const featureSetText = "I then prioritized possible initial features based on my previous research.";
export const sitemapText = "Looking at the MVP, and thinking about how long it would take to develop each feature, I ended up deciding to only implement P1 and P2 features for it. Those decisions naturally led to a fairly simple sitemap.";
export const userFlowText = "From the sitemap, I created an MVP User Flow, taking the users through all pages of the design I intended to mock up in Figma.";
export const lofiText = "Before starting my Wireframes, I decided to implement a website and Android app since a core part of the MVP software was going to be file system access, and Apple doesn’t really do that. Furthermore, Android allows sideloading, which can save me from having to pay to put early versions of the app on the app store.\n\nI started out my Low Fidelity Wireframes with 2 options for each main sitemap screen in Desktop and Mobile. This first option was the one that users didn’t like as much.";
export const userTestingText = "The rest of the feedback was for minor layout changes. Anything 3/5 users asked for, I decided to implement eventually. The stitch shorthand modal was too far out of scope to be part of the MVP. Below is a polished version of what the users liked.";
export const brandingText1 = "Having decided what feedback I would act upon, it was now time to start working on branding. This is where I had to most fun in this project.\n\nAround this time I was working on product statements, the name "
export const brandingText2 = "Amigurumate"
export const brandingText3 = " naturally came to me as a way to pay homage to the Japanese term Amigurumi, which is the crochet of stuffed creatures, and Mate, denoting companion.\n\nFirst, I started thinking about color scheme. I had an image of my head of a monochromatic purple color scheme, and I also wanted to emphasize creativity. It was serindipitous that lighter purples denote creativity in color psychology. Purple has also been associated with artistic and spiritual pursuits, which fit my experiences with crochet.";
export const brandingText4 = "I then worked on choosing typefaces. Given that my research indicated that a notable percentage of fiber artists suffer from afflictions like dyslexia and ADHD, I chose Luciole for my body typeface as it was designed for that purpose and is eminently readable. I wanted a fun, stylized typeface that was still readable for my title, and Rosario won the tournament from an initial list of 20 options.";
export const brandingText5 = "Now, it was finally time for the Logo. I realized that upside down crochet stitches look like a capital letter A. I drew out what a stitch looked like in figma, then designed a version of that from a crochet diagram. Then, I had the idea of creating a mascot, inspired by apps like Duolingo, and created a crochet penguin with DALL-E. Finally, I used a diagram of a crochet hook to create an abstract shape that also resembled the letter A. Recursive Sans Casual, Itim, Laila, and Grandstander all felt like they matched the logos. I tested each unique combination on fellow designers and fiber artists, who overwhelmingly chose the highlighted wordmark.";
export const usabilityText = "I integrated all of the feedback and branding into a High Fidelity Wireframe, testing changes regularly. For instance, I turned the logo on the home page into a Hero Section and gave it a dark background from user feedback. The other major feedback from my testing was that the Completed cards were hard to distinguish from the regular cards. So, I changed the colors of the card first, and when subsequent tests showed that the problem still persisted, I shrank the size of the completed card as well.";
export const demoText = "I then prototyped out all of the screens, and created a High-Fidelity Prototype so functional that testers were confused that it wasn't a working app. Please note that any resemblance to existing patterns is coincidental.";
export const conclustionText = "This project was borne from a need I faced during the last half of COVID-19, but has become much more than I ever intended to make. However, it was not a completely smooth experience. I had to deal with scope creep, a tight deadline, and having to make compromises on my vision of the project based on research results and user feedback.\n\nDespite that, I improved significantly in my Figma animation skills (especially with that beautiful mobile hamburger menu animation), and was able to overcome my instinct to protect the sanctity of my work. Both will be integral to my work in all future projects.\n\nIf I had to redo the project, I would have built out all screens and modals at the very beginning rather than let the Low-Fidelity Wireframes be a brief sanity check for the layout.\n\nAfter I implement the existing features, I will next be adding the ability to save and load patterns from file, a stitch glossary, and a popup stitch description when the user clicks or taps a stitch shorthand."
