export const bgText = "When shopping online, I often find myself growing frustrated by missed sales for high quality products when they go on sale for a price I would buy them at. I have had this problem less often on Amazon because of camelcamelcamel in the past, but with my ever decreasing patronage of that site, I was out of luck until honey created their version and called it a "
export const bgText2 = ".\n\nHowever, both honey and camelcamelcamel have failed me when they notified me of a sale for a product that was sold out by the time the notification hit my inbox. Thus I decided to design a proof of concept of how the website would operate with a site that regularly frustrated me in this way. I chose J.Crew.";
export const researchText1 = "I started compiling a list of the features, strengths, and weaknesses of ";
export const researchBold1 = "competitors";
export const researchText2 = " in the space. I studied Honey, Keepa, Banana Republic, and camelcamelcamel. I found that since droplisting is in its infancy as a feature, the time was ripe for J.Crew to simultaneously clear out old inventory and maximize profits and customer goodwill by enabling this feature.";
export const researchText3 = "Next, I spoke with online shoppers in J.Crew’s target demographic for this feature (20-40 year olds), and picked their brains on what they would want out of a droplist, and how they would want it to work specifically in J.Crew’s case. I turned that into an ";
export const researchBold3 = "affinity map";
export const researchText4 = ", which better gave me insights into the commonalities between users.";
export const personaText1 = "Using all this research, I came up with a ";
export const personaBold1 = "persona";
export const personaText2 = " to base my future design decisions off of.";
export const goalText = "With the persona in mind, I came up with goals for the project to support both the business and the customer.";
export const problemStatmentText = "To ensure that my solution would adequately serve both J.Crew and its customers, I created 3 problem statements.";
export const featureSetText = "All of this research helped me create a prioritized feature set with a clear scope limited to logged in users.";
export const userFlowText = "I integrated the necessary features into a singular User Flow.";
export const lofiText = "I was finally ready to start wireframing. This was the toughest part of the project since I had to play within an existing business’s style guide without explicitly being given said style guide. However, the more I worked on the project, the more clarity I gained as to various aspects of the style guide. I created two layout alternatives (four for the Product page), and had some prospective users take a look at them via user testing to help me make sure I was on the right track.";
export const userTestingText = "And it was a good thing I did. I got a ton of feedback that really helped me course-correct.";
export const hifiText1 = "I took that feedback and injected it into a strong high-fidelity wireframe. The previous feedback made it so strong that when I went on to do a round of usability testing, people had a hard time finding any fault with it that wasn’t endemic to the J.Crew brand already.";
export const hifiText2 = "However, this is where I learned that you sometimes have to break the existing style guide for a brand to better serve the User Experience. And that’s what I did when I took that feedback and created my High-Fidelity Prototype.";
export const conclusionText = "The goal of this project was to explore a brand-specific Droplist, and I believe I succeeded in that venture. I had chosen JCrew because they constantly have a sale going on, and their brand is in an interesting position where it could easilty reinvent itself away from its recent fast-fashion label and into high-quality clothing once again.\n\nMy next steps in this project, if I'm ever given the opportunity to actually work with J.Crew on this, would be to design how the email would look, and design the auto-buy flow.\n\nThis project helped teach me how much work goes into working on an existing brand, and how careful you have to be to follow its style guide. I also learned some of when not to follow the style guide, and will continue learning that line in future projects.";
export const HMW2 = "How might we help busy people who miss out on sale items that they have been watching to ensure that they are able to quickly and easily buy the item when it reaches a price they can afford?";
export const HMW3 = "How might we help customer who droplist clothes to gain a better understanding of how much they save by droplisting clothes?";